<template>
  <div class="pay-index">
    <div class="head_nav">
      <van-nav-bar class="head_nar"
                   title="支付页面">
        <template #left>
          <van-icon name="arrow-left"
                    size="1.5rem"
                    color="#fff"
                    @click="go(-1)"
                    v-permission="{'UserOrder':'UserOrder'}" />
        </template>
      </van-nav-bar>
    </div>
    <div class="fofert">
      <div class="fonget">
        <van-cell-group>
          <div>
            <van-row>
              <van-col span="1"></van-col>
              <van-col span="22"
                       class="yuiop">订单描述</van-col>
            </van-row>
            <van-row>
              <van-col span="1"></van-col>
              <van-col span="23"
                       class="yuioper">
                {{ state.payDescription }}</van-col>
            </van-row>
          </div>
          <van-cell title="订单号">
            <template #right-icon>
              <label class="odere">{{ state.orderNo }}</label>
            </template>
          </van-cell>
          <van-cell title="下单时间"
                    v-if="state.Recharge">
            <template #right-icon>
              <label class="odere">{{ state.orderDate }}</label>
            </template>
          </van-cell>
          <van-cell :title="state.payment"
                    :value="state.totalFreight" />
          <div v-if="state.PayCash">
            <!-- 优惠券单元格 -->
            <div v-if="state.useredcom">
              <van-coupon-cell v-if="state.namrepke"
                               :coupons="state.coupons"
                               :chosen-coupon="state.chosenCoupon"
                               @click="state.showList = true" />
              <!-- 优惠券列表 -->
              <van-popup v-model:show="state.showList"
                         round
                         position="bottom"
                         style="height: 90%; padding-top: 4px;">
                <van-coupon-list :coupons="state.coupons"
                                 :show-exchange-bar="false"
                                 :chosen-coupon="state.chosenCoupon"
                                 :disabled-coupons="disabledCoupons"
                                 @change="onChange" />
              </van-popup>
              <div v-if="state.envelopezero">
                <van-cell title="红包"
                          icon="after-sale"
                          v-if="state.namrepke">
                  <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                  <template #right-icon>
                    {{ state.Redenvelopebalance }}&nbsp;&nbsp;<van-radio-group v-model="checkedes"
                                     clickable
                                     @click="Rerpot">
                      <van-radio name="8"></van-radio>
                    </van-radio-group>
                  </template>
                </van-cell>
              </div>
            </div>
            <div v-if="state.conumoot">
              <van-cell title="优惠券"
                        v-if="state.namrepke">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #right-icon>
                  <span class="redfe">-{{state.couponDiscountAmount}}元</span>
                </template>
              </van-cell>
            </div>
            <div v-if="state.isred">
              <van-cell title="红包"
                        icon="after-sale"
                        v-if="state.namrepke">
                <!-- 使用 right-icon 插槽来自定义右侧图标 -->
                <template #right-icon>
                  <span class="redfe">-{{state.redPackDiscountAmount}}元</span>
                </template>
              </van-cell>
            </div>
          </div>
        </van-cell-group>
      </div>
    </div>
    <van-row>
      <van-col span="1"></van-col>
      <van-col span="23"
               class="hsiuy">支付方式</van-col>
    </van-row>
    <div class="fofert">
      <div class="fonget">
        <van-cell-group>
          <van-radio-group v-model="checked">
            <van-cell v-if="false"
                      title="银联"
                      label="安全极速度支付">
              <template #icon>
                <label class="hoserter">
                  <img :src="state.bankcard"
                       class="hoster" /></label>
              </template>
              <template #right-icon>
                <van-radio name="1"
                           @click="hosget(state.unionpay,1)"></van-radio>
              </template>
            </van-cell>
            <van-cell :title="bugethis(state.baler)"
                      :label="state.Balancedisplay"
                      v-if="state.Recharge">
              <template #icon>
                <label class="hoserter">
                  <img :src="state.balanceed"
                       class="hoster" /></label>
              </template>
              <template #right-icon>
                <van-radio v-if="state.Nomoney"
                           name="4"
                           @click="hosget(state.balance,4)"></van-radio>
              </template>
            </van-cell>
            <van-cell title="支付宝"
                      :label="state.Alipaydescription">
              <template #icon>
                <label class="hoserter">
                  <img :src="state.Alipay"
                       class="hoster" /></label>
              </template>
              <template #right-icon>
                <van-radio name="2"
                           v-if="state.tTour"
                           @click="hosget(state.alipay,2)"></van-radio>
              </template>
            </van-cell>
            <van-cell title="微信"
                      :label="state.Wechatpayment">
              <template #icon>
                <label class="hoserter">
                  <img :src="state.wechate"
                       class="hoster" /></label>
              </template>
              <template #right-icon>
                <van-radio name="3"
                           v-if="state.WechatTour"
                           @click="hosget(state.wechat,3)"></van-radio>
              </template>
            </van-cell>
            <van-cell title="线下支付"
                      v-if="state.isAllowPayCash">
              <template #icon>
                <label class="hoserter">
                  <img :src="state.cash"
                       class="hoster" /></label>
              </template>
              <template #right-icon>
                <van-radio name="9"
                           @click="hosget(state.offlinePayment,9)"></van-radio>
              </template>
            </van-cell>
          </van-radio-group>
        </van-cell-group>
      </div>
    </div>

    <div class="posert"
         v-if="state.manger">
      <van-button type="primary"
                  class="hosget"
                  block
                  @click="determine"
                  v-permission="{'PayResult':'PayResult'}"
                  :disabled="state.disabled">{{paymentamount(state.paymentamount)}}</van-button>
    </div>
    <div class="posert"
         v-if="state.Payment">
      <van-button type="primary"
                  class="hosget"
                  block
                  @click="OfflinePayment"
                  v-permission="{'PayResult':'PayResult'}"
                  :disabled="state.disabled">线下支付</van-button>
    </div>
  </div>
</template>
<script>
import Alipay from "@/static/img/pay/icon-pay-alipay.png";
import balance from "@/static/img/pay/icon-pay-balance.png";
import bankcard from "@/static/img/pay/icon-pay-bankcard.png";
import wechat from "@/static/img/pay/icon-pay-wechat.png";
import cash from "@/static/img/pay/cash.jpeg";
import { reactive, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { BackOff } from "@/commonContent/js/comm.js";
import { getUrlParam, returnFloat } from "@/commonContent/js/comm";
import { GetPayInfo } from "@/serviceApi/Pay";
import { GetCurrUserFullFund } from "@/serviceApi/User";
import { Toast } from "vant";
import { PayOrder } from "@/serviceApi/Pay";
export default {
  setup () {
    var router = useRouter();
    const state = reactive({
      Alipay: Alipay,
      balanceed: balance,
      bankcard: bankcard,
      openId: "",
      envelopezero: false,
      isAllowPayCash: false,
      PayCash: true,
      wechate: wechat,
      cash: cash,
      Recharge: false,
      offlinePayment: "",
      unionpay: "unionpay",
      alipay: "alipay",
      wechat: "wechat",
      balance: "balance",
      offlinePayment: "cash",
      Wechatpayment: "",
      manger: true,
      Payment: false,
      WechatTour: true,
      useredcom: false,
      tTour: true,
      conumoot: false,
      isred: false,
      orderNo: "",
      orderDate: "",
      totalFreight: "",
      monyer: 0,
      Redenvelopebalance: 0,
      Balancedisplay: "",
      Nomoney: true,
      chengty: "8",
      disabled: false,
      Paymentmethod: "",
      outTradeNo: "",
      baler: "",
      paymentamount: 0,
      WalletAmount: "",
      Alipaydescription: "",
      Recharge: true,
      totalFreights: "",
      payment: "付款金额",
      payDescription: "",
      remainAmount: 0,
      payPlatform: 4,
      userRedPackAmount: 0,
      namrepke: false,
      redPackDiscountAmount: "",
      couponDiscountAmount: "",
      coupons: [],
      donotuse: [],
      showList: false,
      chosenCoupon: -1,
      Preferentialprice: 0,
      couponRecordId: "",
      isredpacke: true,
    });
    onMounted(() => {
      let outTradeNo = getUrlParam("outTradeNo");
      let Recharge = getUrlParam("Recharge");

      if (Recharge == "") {
        state.Recharge = true;
        state.payment = "付款金额";
        state.namrepke = true;
      } else {
        state.Recharge = false;
        state.payment = "充值金额";
        state.namrepke = false;
      }
      let params = {
        Filter: { redPackStatus: 1 },
      };
      if (outTradeNo != "") {
        GetPayInfo(outTradeNo).then((res) => {
          if (res.code == 1) {
            if (res.data.payOrder.payStatus != 1) {
              state.manger = false;
              Toast.fail("订单已支付")
              router.replace({ path: "/Order/UserOrderList" });

            }
            state.orderNo = res.data.payOrder.outTradeNo;
            state.openId = res.data.openId;
            if (!Recharge) {
              state.isAllowPayCash = res.data.payOrder.isAllowPayCash;
            }
            if (Recharge == "") {
              state.orderDate = res.data.orderInfo.orderDate;
            }
            else {
              state.Recharge = false;
            }
            state.payDescription = res.data.payOrder.payDescription;
            state.totalFreight = res.data.payOrder.payAmount + res.data.payOrder.appSubsidyAmount + "元";
            state.monyer = res.data.payOrder.payAmount;
            state.paymentamount = res.data.payOrder.payAmount + res.data.payOrder.appSubsidyAmount;
            state.totalFreights = res.data.payOrder.payAmount;
            if (Recharge == "") {
              if (res.data.orderInfo.redPackDiscountAmount != 0 || res.data.orderInfo.couponDiscountAmount != 0 || res.data.orderInfo.systemDiscountAmount != 0) {
                state.useredcom = false;
                if (res.data.orderInfo.redPackDiscountAmount != 0) {
                  state.isred = true;
                }
                if (res.data.orderInfo.couponDiscountAmount != 0) {
                  state.conumoot = true;
                }
                state.isredpacke = false;
                state.couponRecordId = "";
                state.redPackDiscountAmount = res.data.orderInfo.redPackDiscountAmount;
                state.couponDiscountAmount = res.data.orderInfo.couponDiscountAmount;
                if (res.data.payOrder.payAmount - (res.data.orderInfo.redPackDiscountAmount + res.data.orderInfo.couponDiscountAmount + res.data.orderInfo.systemDiscountAmount) < 0) {
                  state.paymentamount = 0
                }
                else {
                  state.paymentamount = res.data.payOrder.payAmount - (res.data.orderInfo.redPackDiscountAmount + res.data.orderInfo.couponDiscountAmount + res.data.orderInfo.systemDiscountAmount)
                }
              }
              else {
                state.useredcom = true;
              }
            }
            GetCurrUserFullFund(true).then((res) => {
              if (res.code == 1) {
                state.userRedPackAmount = res.data.redPack;
                state.baler = res.data.balance;
                state.WalletAmount = res.data.balance;
                for (let index = 0; index < res.data.couponList.length; index++) {
                  if (res.data.couponList[index].couponStatus == 1 && state.totalFreights >= res.data.couponList[index].couponCondition && res.data.couponList[index].couponContent <= state.totalFreights) {
                    var parems = {
                      available: res.data.couponList[index].couponRecordId /* 优惠券id */,
                      condition:
                        res.data.couponList[index].couponCondition /* 满减条件 */,
                      name: res.data.couponList[index].couponName /* 优惠券名称 */,
                      value:
                        res.data.couponList[index].couponContent *
                        100 /* 折扣券优惠金额，单位分 */,
                      startAt:
                        Date.parse(
                          new Date(res.data.couponList[index].effectiveDate)
                        ) / 1000 /* 卡有效开始时间 (时间戳, 单位秒) */,
                      endAt:
                        Date.parse(new Date(res.data.couponList[index].expiryDate)) /
                        1000 /* 卡失效日期 (时间戳, 单位秒) */,
                      valueDesc:
                        res.data.couponList[index]
                          .couponContent /* 折扣券优惠金额文案 */,
                      unitDesc: "元" /* 单位文案 */,
                      description:
                        res.data.couponList[index]
                          .remark /* 描述信息，优惠券可用时展示 */,
                    };
                    if (parems.condition > 0) {
                      parems.condition =
                        "满" + parems.condition + "元可以\n使用优惠券";
                    } else {
                      parems.condition =
                        "无使用门槛\n最多优惠" + parems.valueDesc + "元";
                    }
                    state.coupons.push(parems);
                  } else if (res.data.couponList[index].couponStatus != 2) {
                    var parems = {
                      available: res.data.couponList[index].couponId /* 优惠券id */,
                      condition:
                        res.data.couponList[index].couponCondition /* 满减条件 */,
                      name: res.data.couponList[index].couponName /* 优惠券名称 */,
                      value:
                        res.data.couponList[index].couponContent *
                        100 /* 折扣券优惠金额，单位分 */,
                      startAt:
                        Date.parse(
                          new Date(res.data.couponList[index].effectiveDate)
                        ) / 1000 /* 卡有效开始时间 (时间戳, 单位秒) */,
                      endAt:
                        Date.parse(new Date(res.data.couponList[index].expiryDate)) /
                        1000 /* 卡失效日期 (时间戳, 单位秒) */,
                      valueDesc:
                        res.data.couponList[index]
                          .couponContent /* 折扣券优惠金额文案 */,
                      unitDesc: "元" /* 单位文案 */,
                      reason:
                        res.data.couponList[index]
                          .remark /* 不可用原因，优惠券不可用时展示 */,
                    };
                    if (parems.condition > 0) {
                      parems.condition =
                        "满" + parems.condition + "元可以\n使用优惠券";
                    } else {
                      parems.condition =
                        "无使用门槛\n最多优惠" + parems.valueDesc + "元";
                    }paymentamount
                    state.donotuse.push(parems);
                  }
                }
                if (res.data.redPack > 0) {
                  state.envelopezero = true;
                }
                else {
                  state.isredpacke = false;
                }
                if (Recharge == "") {
                  Calculatetheprice();
                } else {
                  state.paymentamount = state.monyer;
                }
                var sunAoumt = res.data.balance + state.remainAmount
                if (sunAoumt >= state.totalFreights) {
                  state.Balancedisplay = "";
                  state.Nomoney = true;

                } else {
                  state.Nomoney = false;
                  state.Balancedisplay = "余额不足,请先充值";
                }
                if (
                  Recharge == "" && sunAoumt >= state.totalFreights
                ) {
                  checked.value = "4";
                  state.Paymentmethod = "balance";
                  state.payPlatform = 4;
                } else if (
                  navigator.userAgent.toLowerCase().indexOf("micromessenger") >=
                  0
                ) {
                  checked.value = "3";
                  state.Paymentmethod = "wechat";
                  state.payPlatform = 3;
                } else {
                  checked.value = "2";
                  state.Paymentmethod = "alipay";
                  state.payPlatform = 2;
                }
              } else {
                Toast.fail(res.msg);
                checked.value = "2";
                state.payPlatform = 2;
                state.Paymentmethod = "alipay";
              }
            });
          } else {
            Toast.fail(res.msg);
          }
        });
      } else {
        Toast.fail("参数错误");
      }
      if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
        state.Wechatpayment = "";
        state.Alipaydescription = "若发生退款,需扣除0.6%的手续费请复制链接在浏览器进行支付";
        state.WechatTour = true;
        state.tTour = false;
      } else {
        state.WechatTour = false;
        state.tTour = true;
        state.Alipaydescription = "若发生退款,需扣除0.6%的手续费";
        state.Wechatpayment = "请在微信浏览器操作支付";
      }
    });

    const go = (val) => {
      BackOff(router.currentRoute.value)
      if (router.currentRoute.value.query.driverOptionVal > 0) {
        return router.push({ path: "/Order/UserOrderList", query: { orderStatus: router.currentRoute.value.query.driverOptionVal } })
      }
      if (router.currentRoute.value.query.driverOptionVal == 0 && router.currentRoute.value.query.driverOptionVal != "") {
        return router.push({ path: "/Order/UserOrderList", query: { orderStatus: router.currentRoute.value.query.driverOptionVal } })
      }
      router.back(val);
    };
    const hosget = (val, index) => {
      state.Paymentmethod = val;
      state.payPlatform = index;
      if (val == "cash" && index == 9) {
        state.PayCash = false;
        state.Payment = true;
        state.manger = false;
      }
      else {
        state.PayCash = true;
        state.Payment = false;
        state.manger = true;
      }
    };
    const bugethis = (val) => {
      return "余额" + val + "元";
    };
    const checked = ref("");
    const determine = () => {
      let Recharge = getUrlParam("Recharge");
      if (Recharge != "") {
        state.isredpacke = false;
      }
      var parems = {
        outTradeNo: state.orderNo,
        isUseRedPack: state.isredpacke,
        couponRecordId: state.couponRecordId,
        payPlatform: state.payPlatform
      };
      PayOrder(parems).then((res) => {
        state.disabled = false;
        if (res.code == 1) {
          if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
            window.location.href = "" + process.env.VUE_APP_API_PAY + "/pay?outTradeNo=" + state.orderNo + "&platform=" + state.Paymentmethod + "&openId=" + state.openId + "";
          } else {
            window.location.href = "" + process.env.VUE_APP_API_PAY + "/pay?outTradeNo=" + state.orderNo + "&platform=" + state.Paymentmethod + "";
          }
        }
        else {
          Toast.fail(res.msg)
        }


      });
    };
    const checkedes = ref("8");
    const Rerpot = () => {
      if (state.chengty == "8") {
        checkedes.value = "0";

        state.chengty = 0;
        state.isredpacke = false
      } else {
        checkedes.value = "8";
        state.isredpacke = true
        state.chengty = 8;
      }
      Calculatetheprice();
      if (state.WalletAmount >= state.paymentamount) {
        state.Balancedisplay = "";
        state.Nomoney = true;
        checked.value = "4";
        state.Paymentmethod = "balance";
        state.payPlatform = 4;
      }
      else {
        state.Nomoney = false;
        state.Balancedisplay = "余额不足,请先充值";
        if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
          checked.value = "3";
          state.Paymentmethod = "wechat";
          state.payPlatform = 3;
        }
        else {
          checked.value = "2";
          state.Paymentmethod = "alipay";
          state.payPlatform = 2;

        }
      }

    };
    const paymentamount = (val) => {
      return "付款：" + returnFloat(val) + "元";
    };
    const onChange = (index) => {
      state.showList = false;
      state.chosenCoupon = index;
      if (index == -1) {
        Calculatetheprice();
        state.Preferentialprice = 0;
      } else {
        state.Preferentialprice = state.coupons[index].valueDesc;
        state.couponRecordId = state.coupons[index].available
        Calculatetheprice();

      }
      if (state.WalletAmount >= state.paymentamount) {
        state.Balancedisplay = "";
        state.Nomoney = true;
        checked.value = "4";
        state.Paymentmethod = "balance";
        state.payPlatform = 4;
      }
      else {
        state.Nomoney = false;
        state.Balancedisplay = "余额不足,请先充值";
        if (navigator.userAgent.toLowerCase().indexOf("micromessenger") >= 0) {
          checked.value = "3";
          state.Paymentmethod = "wechat";
          state.payPlatform = 3;
        }
        else {
          checked.value = "2";
          state.Paymentmethod = "alipay";
          state.payPlatform = 2;
        }
      }
    };
    const Calculatetheprice = () => {
      var sumprice = state.totalFreights; //总价格
      var Couponamount = state.Preferentialprice; //优惠券优惠金额
      var userRedPackAmount = state.userRedPackAmount; //用户红包余额
      if (state.chosenCoupon > -1) {
        sumprice = sumprice - Couponamount;
      }
      state.remainAmount =
        sumprice > userRedPackAmount ? userRedPackAmount : sumprice;
      state.remainAmount = state.remainAmount;
      state.Redenvelopebalance = state.remainAmount;
      state.Redenvelopebalance = state.Redenvelopebalance.toFixed(2);
      if (state.chengty == "8") {
        sumprice = sumprice - state.remainAmount;
      }
      state.paymentamount = returnFloat(sumprice);
    };
    const OfflinePayment = () => {
      var parems = {
        outTradeNo: state.orderNo,
        payPlatform: state.payPlatform
      };
      state.disabled = true;
      PayOrder(parems).then((res) => {
        if (res.code == 1) {
          state.disabled = false;
          let outTradeNo = getUrlParam("outTradeNo");
          router.replace({ path: "/Pay/PayResult", query: { outTradeNo: outTradeNo } })
        }
        else {
          state.disabled = false;
          Toast.fail(res.msg)
        }
      });
    }
    return {
      state,
      go,
      checked,
      hosget,
      determine,
      bugethis,
      checkedes,
      Rerpot,
      paymentamount,
      onChange,
      disabledCoupons: state.donotuse,
      Calculatetheprice,
      OfflinePayment,
    };
  },
};
</script>
<style>
</style>
