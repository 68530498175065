import { PostApi, GetApi } from "../utils/request";
import ApiVersion from "@/Enum/enumeration"

var CURR_VIEW_VERSION = ApiVersion.ApiVersion.API_VIEW_VERSION.CURR_API_VIEW_VERSION;
var VIEW_VERSION = CURR_VIEW_VERSION == 'V0' ? '' : `/M/${CURR_VIEW_VERSION}`;

export const GetPayInfo = (params) => {
  //获取支付信息
  return GetApi({
    url: `/api${VIEW_VERSION}/Pay/Pay/GetPayInfo`,
    data: { outTradeNo: params },
  });
};
export const PayOrder = (params) => {
  //红包优惠券使用
  var data = {
    url: `/api${VIEW_VERSION}/Pay/Pay/PayOrder`,
    data: params,
  };
  return PostApi(data);
};
